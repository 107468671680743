import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Donation } from '../interfaces/Donation';
import { CustomResponse } from '../interfaces/CustomResponse';

let headers = new HttpHeaders({ "Content-Type": "application/json" })
let options = { headers: headers }

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  private baseUrl: string;

  constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public donate(donation: Donation): Observable<CustomResponse> {
    return this.http.post<CustomResponse>(this.baseUrl + 'api/Donations', donation, options).pipe(
      map(res => {
        return res;
      })
    );
  }
}

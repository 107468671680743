import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './views/home/home.component';
import { RegisterComponent } from './views/register/register.component';
import { EventsComponent } from './views/events/events.component';
import { OurHistoryComponent } from './views/our-history/our-history.component';
import { OurStaffComponent } from './views/our-staff/our-staff.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { MembershipFeesComponent } from './views/membership-fees/membership-fees.component';
import { DonationComponent } from './views/donation/donation.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'register', component: RegisterComponent },
  { path: 'events', component: EventsComponent },
  { path: 'our-history', component: OurHistoryComponent },
  { path: 'our-staff', component: OurStaffComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'membership-fees', component: MembershipFeesComponent },
  { path: 'donation', component: DonationComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../interfaces/AppSettings';

let headers = new HttpHeaders({ "Content-Type": "application/json" })
let options = { headers: headers }

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private baseUrl: string;

  constructor(protected http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public getAppSettings(): Observable<AppSettings> {
    return this.http.get<AppSettings>(this.baseUrl + 'api/AppSettings', options);
  }


}

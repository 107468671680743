import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  public model = {};
  public customResponse: CustomRespose;

  public showRegistrationOk = false;
  public showError = false;
  public errorMessage = 'An expected error ocurred. Please try again';

  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  submit() {

    this.http.post('api/Registration', this.model).subscribe(
      success => {
        this.customResponse = success as CustomRespose;

        if (this.customResponse.type === 'Success') {
          this.showError = false;
          this.showRegistrationOk = true;
          this.toastr.success(this.customResponse.type, this.customResponse.message, {
            positionClass: 'toast-bottom-left'
          });
        }
        else {
          if (this.customResponse.type === 'Error') {
            this.toastr.error(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left'
            });
          }
          else if (this.customResponse.type === 'Warning') {
            this.toastr.warning(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left'
            });
          }
        }      
      }
    );   
  }
}

interface CustomRespose {
  message: string;
  type: string;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-staff',
  templateUrl: './our-staff.component.html',
  styleUrls: ['./our-staff.component.scss']
})
export class OurStaffComponent implements OnInit {
  public staff = [
    { name: 'Ayanaw Hagos', role: 'President', contact: 'AyanawH@ecantn.org', photo: 'Ayanaw.png' },
    { name: 'Tewodros Manaye', role: 'Vice President', contact: 'TewodrosM@ecantn.org', photo: 'Tewodros.png' },
    { name: 'Emnet GebreMicheal', role: 'Secretary', contact: 'EmnetG@ecantn.org', photo: 'Emnet.png' },
    { name: 'Almaz Taye', role: 'Treasury', contact: 'AlmazT@ecantn.org', photo: 'Almaz.png'},  
    { name: 'Yemisrach Mergia', role: 'Communication Director', contact: 'YemisrachM@ecantn.org', photo: 'Yemi.png' },
    { name: 'Zeweter Samuel', role: 'Youth Director', contact: 'ZeweterS@ecantn.org', photo: 'Zee.png' },
    { name: 'Henok Gulilat', role: 'Planning Director', contact: 'Henok@ecantn.org', photo: 'Henok.png'}
  
    
  ]
  constructor() { }

  ngOnInit() {
  }

}

import { Component, Input, OnInit } from "@angular/core";
import { SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
    selector: 'carousel',
    templateUrl: 'carousel.component.html',
    styleUrls: ['carousel.component.scss']
})
export class CarouselComponent implements OnInit {

    @Input() private folderName: string;
    @Input() private imgNameId: string;
    @Input() private fileCount: number;
    @Input() public height: string;
    @Input() public width: string;
    public paths: string[];

    constructor() {
        this.paths = [];
    }

    ngOnInit(): void {
        this.generatePaths();
    }

    private generatePaths() {
        for(let i = 1; i <= this.fileCount; i++) {
            this.paths.push(`url('assets/images/${this.folderName}/${this.imgNameId}${i}.jpg')`);
        }
    }

    public slides = [
        `<div class="slide-image1"></div>`,
        `<div class="slide-image2"></div>`,
        `<div class="slide-image3"></div>`,
    ];

    public config: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    private scrollbar: SwiperScrollbarInterface = {
        el: '.swiper-scrollbar',
        hide: false,
        draggable: true
    };

    private pagination: SwiperPaginationInterface = {
        el: '.swiper-pagination',
        clickable: true,
        hideOnClick: false
    };
}
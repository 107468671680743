import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MembershipFeesService } from 'src/app/services/membership-fees.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from 'src/app/interfaces/AppSettings';
import { CustomResponse } from 'src/app/interfaces/CustomResponse';
import { MembershipFees } from 'src/app/interfaces/MembershipFees';
import paypal from 'src/app/paypal';

@Component({
  selector: 'app-membership-fees',
  templateUrl: './membership-fees.component.html',
  styleUrls: ['./membership-fees.component.scss']
})
export class MembershipFeesComponent implements OnInit, AfterViewInit {

  public years: number[];
  public currentYear: number = new Date().getFullYear();
  public showProcessing = false;
  public membershipFeesForm: FormGroup;
  public enabledPayPalButton: boolean;
  public enabledPaymentTestButton: boolean;
  private membershipFeeAmount: number;
  public customResponse: CustomResponse;

  constructor(private fb: FormBuilder, 
              private membershipFeesService: MembershipFeesService,
              private appSettingsService: AppSettingsService,
              private toastr: ToastrService) {
    this.years = this.range(this.currentYear,this.currentYear + 20, 1);
    this.membershipFeesForm = fb.group({
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[0-9.]+$/)]],
      fullName: ['', [Validators.required]],
      paymentForYear: ['', [Validators.required]],
      amount: ['']
    });                   
                                                                                                                                                                                                                                                                                        
  }
  
  ngOnInit() {
    this.appSettingsService.getAppSettings().subscribe(
      (appSettings: AppSettings) => {
        console.log(appSettings);
        this.membershipFeesForm.get('amount').setValue(appSettings.membershipFeeAmount);
        this.membershipFeeAmount = appSettings.membershipFeeAmount;
        this.enabledPayPalButton = appSettings.enabledPayPalButton;
        this.enabledPaymentTestButton = appSettings.enabledPaymentTestButton;
      },
      (error) => {
        console.log(error);
      }
    );
    
  }

  ngAfterViewInit() {
    const self = this;
    paypal.Buttons({
      style: {
          layout: 'vertical',
          color:  'blue',
          shape:  'rect',
          label:  'pay'
      },
      createOrder: function (data, actions) {        
        return actions.order.create({
          purchase_units: [{
            "amount": {
              "currency_code": "USD",
              "value": self.membershipFeeAmount
            }
          }]
        });
      },
      onInit: function (data, actions) {
        actions.disable();

        self.membershipFeesForm.statusChanges.subscribe(status => {
          if (status == 'VALID') {
            actions.enable();
          } else {
            actions.disable();

          }
        });
      },
      onApprove: function(data, actions) {
        let paymentId: any;
        let transactionStatus: any;
        let payerEmailAddress: any;
        let payerFullName: any;

        actions.order.capture().then(function (orderData) {
          self.showProcessing = true;
          paymentId = orderData['id'];
          transactionStatus = orderData['status']
          payerEmailAddress = orderData['payer']['email_address'];
          payerFullName = orderData['payer']['name']['given_name'] + ' ' + orderData['payer']['name']['surname'];
          //console.log(orderData);
          self.saveMembershipFeePayment(paymentId, transactionStatus, payerEmailAddress, payerFullName);
          self.membershipFeesForm.reset();
        });
      },
      onError: function(err) {
        console.log(err);
      }
      
    }).render('#paypal-button-membership');
  }

  saveMembershipFeePayment(paymentId: any, transactionStatus: any, payerEmailAddress: any, payerFullName: any) {
    let dataForm: MembershipFees = {
      phoneNumber: this.membershipFeesForm.get('phoneNumber').value,
      fullName: this.membershipFeesForm.get('fullName').value,
      year: this.membershipFeesForm.get('paymentForYear').value,
      amount: this.membershipFeesForm.get('amount').value,
      transactionId: paymentId,
      transactionStatus: transactionStatus,
      payerEmailAddress: payerEmailAddress,
      payerFullName: payerFullName,
    }
    
    this.membershipFeesService.pay(dataForm).subscribe(
      success => {
        this.customResponse = success as CustomResponse;

        if (this.customResponse.type === 'Success') {         
          this.toastr.success(this.customResponse.type, this.customResponse.message, {
            positionClass: 'toast-bottom-left',
            timeOut: 15000,
            closeButton: true,            
          });         
        }
        else {
          if (this.customResponse.type === 'Error') {
            this.toastr.error(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left',
              timeOut: 15000,
              closeButton: true,
            });
          }
          else if (this.customResponse.type === 'Warning') {
            this.toastr.warning(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left',
              timeOut: 15000,
              closeButton: true,
            });
          }
        } 
        this.showProcessing = false;
      },
      error => {
        console.log("Error: " + error);
        this.showProcessing = false;
      }
    );
  }

  /* Generates an array of numbers between the indicated ranges, this method will be used for the Payment for year field */
  public range(start: number, stop: number, step: number): Array<number> {
    return Array.from({ length: (stop - start) / step +1}, (_, i) => start + (i * step));
  }
  
}

import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isExpanded = false;

  ngOnInit(): void {
      
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openPDF(pdf) {
    window.open(`/assets/documents/bylaws/${pdf}`, '_blank')
  }

  /* donate() {
    PayPal.Donation.Button({
      env:'production',
      hosted_button_id:'4ET54ZJJ95Y4W',
      image: {
        src:'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
        alt:'Donate with PayPal button',
        title:'PayPal - The safer, easier way to pay online!',
      }
    })
    .render('#donate-button');
  } */
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomResponse } from '../../interfaces/CustomResponse';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public model = {};
  public showRegistrationOk = false;
  public showError = false;
  public customResponse: CustomResponse;

  constructor(private http: HttpClient, private toastr: ToastrService) {
  }

  ngOnInit() {
  }

  submit() {
    this.http.post('api/Contact', this.model).subscribe(
      success => {
        this.customResponse = success as CustomResponse;

        if (this.customResponse.type === 'Success') {
          this.showError = false;
          this.showRegistrationOk = true;          
          this.toastr.success(this.customResponse.type, this.customResponse.message, {
            positionClass: 'toast-bottom-left'
          });     
        }
        else {
          if (this.customResponse.type === 'Error') {
            this.toastr.error(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left'
            });
          }
          else if (this.customResponse.type === 'Warning') {
            this.toastr.warning(this.customResponse.type, this.customResponse.message, {
              positionClass: 'toast-bottom-left'
            });
          }
        } 
      }
    );
  }
}


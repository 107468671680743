import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

/* Carousel config */

  public folderName2022 = "gallery/2022";
  public fileCount2022 = 15;

  public folderName2021 = "gallery/2021";
  public fileCount2021 = 30;

  public folderName2019 = "gallery/2019";
  public fileCount2019 = 30;

  public folderName2018 = "gallery/2018";
  public fileCount2018 = 20;

  public folderName2017 = "gallery/2017";
  public fileCount2017 = 20;

  public imgNameId = "image"
  public height = '75vh';
  public width = '100%';

  public years = [2022,2021, 2019, 2018, 2017];
  public selectedYear = this.years[0];

  constructor() { 

  }

  ngOnInit() {

  }

}

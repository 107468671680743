/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { ToastrModule } from 'ngx-toastr';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppRoutingModule } from './app-routing.module';

/* Components */
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CarouselComponent } from './components/carousel/carousel.component';

/* Views */
import { HomeComponent } from './views/home/home.component';
import { RegisterComponent } from './views/register/register.component';
import { EventsComponent } from './views/events/events.component';
import { OurHistoryComponent } from './views/our-history/our-history.component';
import { OurStaffComponent } from './views/our-staff/our-staff.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { MembershipFeesComponent } from './views/membership-fees/membership-fees.component';
import { DonationComponent } from './views/donation/donation.component';
 
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CarouselComponent,
    
    HomeComponent,
    RegisterComponent,
    EventsComponent,
    OurHistoryComponent,
    OurStaffComponent,
    ContactUsComponent,
    GalleryComponent,
    MembershipFeesComponent,
    DonationComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SwiperModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DonationService } from 'src/app/services/donation.service';
import { ToastrService } from 'ngx-toastr';
import { Donation } from 'src/app/interfaces/Donation';
import { CustomResponse } from '../../interfaces/CustomResponse';
import { AppSettings } from 'src/app/interfaces/AppSettings';
import { AppSettingsService } from 'src/app/services/app-settings.service';
import paypal from 'src/app/paypal';

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss']
})
export class DonationComponent implements OnInit, AfterViewInit {

  public donationForm: FormGroup;
  public customResponse: CustomResponse;
  public enabledPayPalButton: boolean;
  public enabledPaymentTestButton: boolean;
  public showProcessing = false;

  constructor(private fb: FormBuilder,
    private donationService: DonationService,
    private appSettingsService: AppSettingsService,
    private toastr: ToastrService) {
    this.donationForm = fb.group({
      fullName: ['', Validators.required],
      anonymous: [false],
      phoneNumber: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[0-9.]+$/)]],
      amount: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.appSettingsService.getAppSettings().subscribe(
      (appSettings: AppSettings) => {
        this.enabledPayPalButton = appSettings.enabledPayPalButton;
        this.enabledPaymentTestButton = appSettings.enabledPaymentTestButton;
      },
      (error) => {
        console.log('Ups! There is a error...');
      }
    );
  }

  ngAfterViewInit() {
    const self = this;
    paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'blue',
        shape: 'rect',
        label: 'pay'
      },
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [{ "amount": { "currency_code": "USD", "value": self.donationForm.get('amount').value } }]
        });
      },
      onInit: function (data, actions) {
        actions.disable();

        self.donationForm.statusChanges.subscribe(status => {
          if (status == 'VALID') {
            actions.enable();
          } else {
            actions.disable();

          }
        });
      },
      onApprove: function (data, actions) {
        let paymentId: any;
        let transactionStatus: any;
        let payerEmailAddress: any;
        let payerFullName: any;

        return actions.order.capture().then(function (orderData) {
          self.showProcessing = true;
          paymentId = orderData['id'];
          transactionStatus = orderData['status']
          payerEmailAddress = orderData['payer']['email_address'];
          payerFullName = orderData['payer']['name']['given_name'] + ' ' + orderData['payer']['name']['surname'];
          self.saveDonationPayment(paymentId, transactionStatus, payerEmailAddress, payerFullName);
          self.donationForm.reset();

        });
      },
      onError: function (err) {
        console.log(err);
      }

    }).render('#paypal-button-donation');
  }

  public saveDonationPayment(paymentId: any, transactionStatus: any, payerEmailAddress: any, payerFullName: any) {
    let dataForm: Donation = {
      phoneNumber: this.donationForm.get('phoneNumber').value,
      fullName: this.donationForm.get('fullName').value,
      amount: this.donationForm.get('amount').value,
      transactionId: paymentId,
      transactionStatus: transactionStatus,
      payerEmailAddress: payerEmailAddress,
      payerFullName: payerFullName,
    }

    this.donationService.donate(dataForm).subscribe(
      success => {
        this.customResponse = success as CustomResponse;
        this.showProcessing = false;
      }
    );
  }

  public isAnonymous() {
    let value = this.donationForm.get('anonymous').value;
    value = !value;
    if (value == true) {
      this.donationForm.get('fullName').setValue('Anonymous');
      this.donationForm.get('fullName').disable();
    } else {
      this.donationForm.get('fullName').setValue('');
      this.donationForm.get('fullName').enable();
    }
  }

  get fullName() {
    return this.donationForm.get('fullName');
  }

  get phoneNumber() {
    return this.donationForm.get('phoneNumber');
  }

  get amount() {
    return this.donationForm.get('amount');
  }
}



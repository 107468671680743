import { Component } from '@angular/core';
/* import { SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
 */
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CustomResponse } from '../../interfaces/CustomResponse'

declare var $ :any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  model = {};
  message = "";
  verified = false;
  registered = false;
  customResponse: CustomResponse;

  /* Carousel config */
  public folderName = "home";
  public imgNameId = "slide";
  public height = '300px';
  public width = '100%';
  public fileCount = 10;
  options = {
    backdrop: 'static'
  }

  /*
  public slides = [
    `<div class="slide-image1"></div>`,   
    `<div class="slide-image2"></div>`,
    `<div class="slide-image3"></div>`,
  ];

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  }; */

  constructor(private http: HttpClient, private router: Router) {
  }

  verify() {
    this.http.post('api/Registration/Validate', this.model).subscribe(
      success => {
        this.customResponse = success as CustomResponse;
        this.verified = true;
        if (this.customResponse.type === 'Success') {
          this.message = `You are not registered yet. Thank you for checking.`;
          this.registered = false;
        }
        else {
          if (this.customResponse.type === 'Error') {
            this.message = this.customResponse.message;
          }
          else if (this.customResponse.type === 'Warning') {
            this.message = this.customResponse.message; //`You are already registered. Thank you for checking.`;
            this.registered = true;
          }
        }     
      }
    );   
  }

  openModalVerification() {
    this.model = {};
    this.registered = false;
    this.verified = false;
    this.message = "";
    $('#registrationModal').modal(this.options);
  }

  goToRegistration() {
    this.router.navigate(["register"]);
  }

}


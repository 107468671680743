import { Component } from '@angular/core';

@Component({
  selector: 'app-events-component',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {
  public events = [
    { image: 'CurrentEvent.jpg'},
   // { image: 'Event2.jpg'},
    //{ image: 'Event3.jpg'},
  ]
}
